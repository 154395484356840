<template>
  <div id="section6">
    <div class="fondoGrisClaro">
      <b-container fluid>
        <b-container>
          <br />
          <h2>{{$t("payTitulo")}}</h2>
          <b-row >
            <b-col lg="2" md="4" sm="12" v-for="(item, idx) in payList" :key="idx">
              <b-row>
                <b-col cols="4" class="text-center">
                  <b-img :src="item.icon"  style="width: 8em"/>
                </b-col>
                <b-col cols="8">
                  {{item.txt}}
                </b-col>
              </b-row>
              <br />
            </b-col>
          </b-row>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "COINS ACCEPTED"
  },
  "es":{
    "payTitulo": "MONEDAS ACEPTADAS"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payList: [
        {
          icon: require("@/assets/images/bandera/usd.jpg")
          , txt: "(USD) DOLAR U.S.A"
        },
        {
          icon: require("@/assets/images/bandera/gbp.jpg")
          , txt: "(GBP) LIBRA ESTERLINA"
        },
        {
          icon: require("@/assets/images/bandera/chf.jpg")
          , txt: "(CHF) FRANCO SUIZO"
        },
        {
          icon: require("@/assets/images/bandera/sek.jpg")
          , txt: "(SEK) CORONA SUECA"
        },
        {
          icon: require("@/assets/images/bandera/zar.jpg")
          , txt: "(ZAR) RAND SUDAFRICANO"
        },
        {
          icon: require("@/assets/images/bandera/rus.jpg")
          , txt: "(RUS) RUBLO"
        },
        {
          icon: require("@/assets/images/bandera/nok.jpg")
          , txt: "(NOK) CORONA NORUEGA"
        },
        {
          icon: require("@/assets/images/bandera/nzd.jpg")
          , txt: "(NZD) DOLAR NEOZELANDES"
        },
        {
          icon: require("@/assets/images/bandera/mxn.jpg")
          , txt: "(MXN) PESO MEJICANO"
        },
        {
          icon: require("@/assets/images/bandera/jpy.jpg")
          , txt: "(JPY) YEN JAPONÉS"
        },
        {
          icon: require("@/assets/images/bandera/hkd.jpg")
          , txt: "(HKD) DOLAR HONG KONG"
        },
        {
          icon: require("@/assets/images/bandera/dkk.jpg")
          , txt: "(DKK) CORONA DANESA"
        },
        {
          icon: require("@/assets/images/bandera/czk.jpg")
          , txt: "(CZK) CORONA CHECA"
        },
        {
          icon: require("@/assets/images/bandera/cny.jpg")
          , txt: "(CNY) YUAN RENMINBI"
        },
        {
          icon: require("@/assets/images/bandera/cad.jpg")
          , txt: "(CAD) DOLAR CANADIENSE"
        },
        {
          icon: require("@/assets/images/bandera/aud.jpg")
          , txt: "(AUD) DOLAR AUSTRALIANO"
        },
        {
          icon: require("@/assets/images/bandera/clp.jpg")
          , txt: "(CLP) PESO CHILENO"
        },
        {
          icon: require("@/assets/images/bandera/eek.jpg")
          , txt: "(EEK) CORONA ESTONIA"
        },
        {
          icon: require("@/assets/images/bandera/gip.jpg")
          , txt: "(GIP) LIBRA GIBRALTAREÑA"
        },
        {
          icon: require("@/assets/images/bandera/huf.jpg")
          , txt: "(HUF) FLORINT"
        },
        {
          icon: require("@/assets/images/bandera/isk.jpg")
          , txt: "(ISK) CORONA ISLANDESA"
        },
        {
          icon: require("@/assets/images/bandera/rs.jpg")
          , txt: "(RS) RUPIA INDIA"
        },
        {
          icon: require("@/assets/images/bandera/ils.jpg")
          , txt: "(ILS) SHEKEL ISRAELI"
        },
        {
          icon: require("@/assets/images/bandera/kwd.jpg")
          , txt: "(KWD) DINAR KUWAITI"
        },
        {
          icon: require("@/assets/images/bandera/pab.jpg")
          , txt: "(PAB) BALBOA PANAMÁ"
        },
        {
          icon: require("@/assets/images/bandera/qar.jpg")
          , txt: "(QAR) RIYAL QATARÍ"
        },
        {
          icon: require("@/assets/images/bandera/sar.jpg")
          , txt: "(SAR) RIYAL SAUDÍ"
        },
        {
          icon: require("@/assets/images/bandera/sgd.jpg")
          , txt: "(SGD) DOLAR SINGAPUR"
        },
        {
          icon: require("@/assets/images/bandera/aed.jpg")
          , txt: "(AED) UAE DIRHAM"
        },
        {
          icon: require("@/assets/images/bandera/ron.jpg")
          , txt: "(RON) LEU RUMANO"
        },
        {
          icon: require("@/assets/images/bandera/try.jpg")
          , txt: "(TRY) LIRA TURCA"
        },
        {
          icon: require("@/assets/images/bandera/bgn.jpg")
          , txt: "(BGN) LEV BÚLGARO"
        },
        {
          icon: require("@/assets/images/bandera/pln.jpg")
          , txt: "(PLN) ZLOTY POLACO"
        },
        {
          icon: require("@/assets/images/bandera/brl.jpg")
          , txt: "(BRL) REAL BRASILEÑO"
        }
      ]
    };
  }
};
</script>