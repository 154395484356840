<template>
  <div id="section2">
    <div class="fondoGrisClaro">
      <b-container fluid>
        <b-container>
          <b-row align-v="center">
            <b-col md="3" sm="12" class="text-right">
              <font-awesome-icon :icon="payIcon" size="4x" />
            </b-col>
            <b-col md="9" sm="12" class="text-left">
              <br />
              <h2>{{$t("payTitulo")}}</h2>
              <ul style="padding-left: 1em;">
                <li v-for="(item, idx) in payText" :key="idx">{{item}}</li>
              </ul>
              <br />
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "BENEFITS FOR THE CARD HOLDER.",
    "payText": [
      "The holder has the possibility to make the payment in his currency or in euros.",
      "The holder knows the exact amount that will be charged from his account: the amount of the ticket corresponds exactly to the extracted amount.",
      "This service applies a mark up (exchange rate commission) in most cases less than your bank would apply.",
      "Exchange rates are updated daily."
    ]
  },
  "es":{
    "payTitulo": "BENEFICIOS PARA EL TITULAR DE LA TARJETA.",
    "payText": [
      "El titular tiene la posibilidad de realizar el pago en su moneda o en euros.",
      "El titular sabe el importe exacto que se le va a cargar en su cuenta, el importe del ticket se corresponde exactamente con el de su extracto.",
      "Este servicio aplica un mark up (comisión de tipo de cambio) en la mayoría de los casos menor al que le aplicaría su banco.",
      "Tipos de cambio actualizados a diario."
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payIcon: ["fas", "credit-card"]
    };
  },
  computed: {
    payText() {
      return this.$t("payText");
    }
  }
};
</script>