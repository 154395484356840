<template>
  <div id="section4">
    <div class="fondoGrisClaro">
      <b-container fluid>
        <b-container>
          <br />
          <h2>{{$t("payTitulo")}}</h2>
          <b-row align-v="center" >
            <b-col md="3" sm="12">
              <b-img :src="payImg2"/>
            </b-col>
            <b-col md="9" sm="12">
              <br />
              <h3>{{$t("payLista1")}}</h3>
              <p class="text-justify">
                <span v-for="(item, idx) in payText" :key="idx">{{item}} <br/></span>
              </p>
              <br />
              <div>
                <b-img :src="payImg1" />
              </div>
            </b-col>
          </b-row>
          <br />
          <h3>{{$t("payLista2")}}</h3>
          <b-row>
            <b-col md="6" sm="12" v-for="(item, idx) in payList" :key="idx">
              <br />
              <h3>{{item.titulo}}</h3>
              <ul style="padding-left: 1em;">
                <li v-for="(item1, idx1) in item.list" :key="idx1">{{item1}}</li>
              </ul>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "OPERATION OF THE MULTI-UNIFORM POS.",
    "payLista1": "1. The POS detects that the card is not issued in euros.",
    "payLista2": "2. The cardholder sees the screen where",
    "payText": [
      "Once the terminal (POS, pin-pad, web page, etc ...) detects the card, the screen automatically shows the exact amount in the cardholder's currency.",
      "At this moment, the POS must be provided to the customer so that he can select his currency.",
      "Offering the option of the service is the key to optimize the advantages of using this service."
    ],
    "payList": [
      {
        "titulo": "If chosen the FOREIGN CURRENCY option:"
        , "list":[
          "The purchase is made in the currency of the client's country of origin."
          , "A bonus of one% per operation is assigned to trade."
          , "The trade is paid in €."
        ]
      },
      {
        "titulo": "If chosen the EUR option:"
        , "list":[
          "The purchase is made in €."
          , "The business does not receive any extra income. It is received by the card issuing bank."
          , "The trade is paid in €."
          , "The customer will not know the exact amount in his currency until the card issuing bank settles the payment."
        ]
      }
    ]
  },
  "es":{
    "payTitulo": "FUNCIONAMIENTO DEL TPV MULTIDIVISA.",
    "payLista1": "1. El TPV detecta que la tarjeta no está emitida en euros.",
    "payLista2": "2. El titular ve la pantalla",
    "payText": [
      "Una vez que el terminal (TPV, pin-pad, página web, etc…) detecta la tarjeta, automáticamente la pantalla muestra el importe exacto en la moneda del titular de la tarjeta.",
      "En este momento, se debe entregar el TPV al cliente para que éste, seleccione su moneda.",
      "Ofrecer la opción del servicio es clave para optimizar las ventajas del uso de este servicio."
    ],
    "payList": [
      {
        "titulo": "Si escoge la opción DIVISA EXTRANJERA:"
        , "list":[
          "La compra se realiza en la moneda del país de origen del cliente."
          , "Se cede al comercio una bonificación de un % por operación."
          , "Se abona en € al comercio."
        ]
      },
      {
        "titulo": "Si escoge la opción EUR:"
        , "list":[
          "La compra se realiza en €."
          , "El comercio no recibe ningún ingreso por bonificación. Lo recibe el banco emisor de la tarjeta."
          , "Se abona en € al comercio."
          , "El cliente no sabrá el importe exacto en su modena de origen hasta que el banco emisor de la tarjeta liquide el pago."
        ]
      }
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payImg1: require("@/assets/images/pay/tvp_pasos-1.png"),
      payImg2: require("@/assets/images/pay/tpv-2.png")      
    };
  },
  computed: {
    payList() {
      return this.$t("payList");
    },
    payText() {
      return this.$t("payText");
    }
  }
};
</script>