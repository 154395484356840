<template>
  <div id="section1">
    <div class="fondoWhite">
      <b-container fluid>
        <b-container>
          <b-row align-v="center">
            <b-col md="3" sm="12" class="text-right">
              <font-awesome-icon :icon="payIcon" size="4x" />
            </b-col>
            <b-col md="9" sm="12" class="text-left">
              <br>
              <h2>
                {{$t("payTitulo")}}
              </h2>
              <p>
                <span v-for="(item, idx) in payText" :key="idx">
                  {{item}}
                </span>
              </p>
              <br>
              <div class="d-flex">
                <span v-for="(item, idx) in payImg" :key="idx">
                  <b-img :src="item"/>
                </span>
              </div>
              <br>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "WHAT IS THE DCC (Dynamic Currency Conversion)?",
    "payText": [
      "It is a service that allows foreign customers to pay in their home currency.",
      "In this way the client knows exactly what is going to be charged from his account.",
      "The cards to which this service can be applied to are Visa and Mastercard."
    ]
  },
  "es":{
    "payTitulo": "¿QUÉ ES EL DCC (Dynamic Currency Conversion)?",
    "payText": [
      "Es un servicio que permite al cliente extranjero pagar en su divisa de origen.",
      "De esta forma el cliente sabe exactamente lo que se le va a cargar en su extracto.",
      "Las tarjetas susceptibles de aplicar este servicio son Visa y Mastercard."
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payIcon: ["fas", "globe-americas"],
      payImg: [
        require("@/assets/images/pay/visa.png"),
        require("@/assets/images/pay/mastercard.png")
      ]
    };
  },
  computed: {
    payText() {
      return this.$t("payText");
    }
  }
};
</script>