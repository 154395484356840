<template>
  <div id="section5">
    <div class="fondoWhite">
      <b-container fluid>
        <b-container>
          <br />
          <h2>{{$t("payTitulo")}}</h2>
          <div v-for="(item, idx) in payList" :key="idx">            
            <h3>{{item.titulo}}</h3>
            <ul style="padding-left: 1em;">
              <li>
                <span v-for="(item1, idx1) in item.text" :key="idx1">
                  <strong v-if="item1.txtS">
                    {{item1.txt}}
                  </strong>
                  <span v-else>
                    {{item1.txt}}
                  </span>
                </span>
              </li>
            </ul>
          </div>
            <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "ARGUMENTARY BEFORE POSSIBLE OBJECTIONS AND FREQUENTLY ASKED QUESTIONS.",
    "payList": [
      {
        "titulo": "Is the exchange rate more expensive than the one my bank applies to me?"
        , "text": [
          {
            "txtS": true,
            "txt": "Usually NO, "
          },
          {
            "txtS": false,
            "txt": "we adapt it to the change of the day, the issuing bank usually applies a major change, based on the ways it is made."
          }
        ]
      },
      {
        "titulo": "Does my bank charge me an extra commission apart from the change?"
        , "text": [
          {
            "txtS": true,
            "txt": "NO, "
          },
          {
            "txtS": false,
            "txt": "the charge you will see in your statement is that of the ticket."
          }
        ]
      },
      {
        "titulo": "If I return the purchase, will I have another change applied? Will they charge me commission again? Will they give me less back?"
        , "text": [
          {
            "txtS": true,
            "txt": "NO, "
          },
          {
            "txtS": false,
            "txt": "the amount charged is returned, without any commission."
          }
        ]
      }
    ]
  },
  "es":{
    "payTitulo": "ARGUMENTARIO ANTE POSIBLES OBJECIONES Y PREGUNTAS FRECUENTES.",
    "payList": [
      {
        "titulo": "¿El tipo de cambio es más caro que el que me aplica mi banco?"
        , "text": [
          {
            "txtS": true,
            "txt": "Habitualmente NO, "
          },
          {
            "txtS": false,
            "txt": "lo adaptamos al cambio del día, el banco emisor suele aplicar un cambio mayor, en base a medias que realiza."
          }
        ]
      },
      {
        "titulo": "¿Mi banco me carga una comisión extra a parte del cambio?"
        , "text": [
          {
            "txtS": true,
            "txt": "NO, "
          },
          {
            "txtS": false,
            "txt": "el cargo que verá en su extracto es el del ticket."
          }
        ]
      },
      {
        "titulo": "Si realizo una devolución de la compra, ¿se me va a aplicar otro cambio? ¿me van a volver a cobrar comisión? ¿me van a devolver menos?"
        , "text": [
          {
            "txtS": true,
            "txt": "NO, "
          },
          {
            "txtS": false,
            "txt": "se devuelve el importe cobrado, sin ningún tipo de comisión."
          }
        ]
      }
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
    };
  },
  computed: {
    payList() {
      return this.$t("payList");
    }
  }
};
</script>