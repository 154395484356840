<template>
  <div id="section3">
    <div class="fondoWhite">
      <b-container fluid>
        <b-container>
          <b-row align-v="center">
            <b-col md="3" sm="12" class="text-right">
              <font-awesome-icon :icon="payIcon" size="4x" />
            </b-col>
            <b-col md="9" sm="12" class="text-left" >
              <br />
              <h2>{{$t("payTitulo")}}</h2>
              <ul style="margin: 0; padding: 0 1em;">
                <li v-for="(item, idx) in payList" :key="idx">{{item}}</li>
              </ul>
              <p style="margin: 1rem 0 !important;">{{$t("payText")}}</p>
              <ul style="padding-left: 1em;">
                <li v-for="(item, idx) in payList1" :key="idx">{{item}}</li>
              </ul>
              <br />
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "BENEFITS FOR THE BUSINESS.",
    "payList": [
      "Cost effectiveness. If the customer decides to pay in their currency, the business benefits through the saving of the discount rate.",
      "Your settlement will remain in euros, in line with the original amount of the purchase."
    ],
    "payText": "In the case of return of the operation, the acceptance or not of the payment in your currency does not have any influence.",
    "payList1": [
      "Image. With this service the foreign customers will be satisfied with the attention.",
      "Ease. The registration of the service in the terminal is simple and free."
    ]
  },
  "es":{
    "payTitulo": "BENEFICIOS PARA EL COMERCIO.",
    "payList": [
      "Rentabilidad. Si el cliente decide pagar en su moneda, el comercio se beneficia a través del ahorro de la tasa de descuento.",
      "Tu liquidación seguirá siendo en euros, por el importe original de la compra."
    ],
    "payText": "En el caso de devolución de la operación, la aceptación o no del pago en su moneda no influye.",
    "payList1": [
      "Imagen. Con este servicio el cliente extranjero quedará satisfecho con la atención.",
      "Facilidad. El alta del servicio en el terminal es sencilla y gratuita."
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payIcon: ["fas", "shopping-cart"]
    };
  },
  computed: {
    payList() {
      return this.$t("payList");
    },
    payList1() {
      return this.$t("payList1");
    }
  }
};
</script>